<template>
  <div class="Videos">
    <div class="banner">
      <img width="100%" src="../assets/banner9.png" alt="" />
      <div class="title">
        <div class="h1">Videos</div>
      </div>
    </div>

    <div class="videos">
      <video
        class="video maodian"
        src="https://www.szinm2020.com/img/HomePage/0826-INM-%E5%AA%92%E4%BD%93%E4%BC%98%E5%8A%BF%EF%BC%88%E5%9B%BD%E8%AF%AD%EF%BC%89-30s%20(2).mp4"
        autoplay
        loop
        muted
        width="100%"
        poster="../assets/banner9.png"
      ></video>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.banner {
  width: 100vw;
  /* height: 756px;
  background: url("../assets/banner9.png") no-repeat;
  background-size: cover; */
  position: relative;
}
.title {
  color: #fff;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  /* z-index: 100; */
  position: absolute;
  top: 40%;
  font-size: 76px;
}
.videos {
  width: 100vw;
  video {
    width: 80vw;
    margin-top: 120px;
    margin-bottom: 300px;
    /* margin: 5% auto 300px; */
  }
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .nav {
    width: 80vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .title .h1 {
    font-size: 36px;
  }
  .videos video {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .title {
    top: 35%;
    .h1 {
      font-size: 20px;
    }
  }
  .videos video {
    width: 90vw;
    margin: 30px auto 50px;
  }
}
</style>
